<ng-container [ngSwitch]="templateType">
  <div *ngSwitchCase="TemplateType.Lottie" class="template__wrapper">
    <div class="template lottie" [class.vertical-centered]="forPrint">
      <lib-cfs-template-preview
        *ngIf="previewOptions$ | async as options"
        [fullScreenWrapper]="true"
        [type]="'lottie'"
        [screenSize]="screenSize"
        [options]="options"
        [isPrintView]="forPrint"
        (printReady)="launchPrint()"
      >
      </lib-cfs-template-preview>
    </div>
  </div>

  <div
    *ngSwitchDefault
    class="template__wrapper"
    [class.for-print]="forPrint"
    [innerHTML]="generatedBody"
  ></div>
</ng-container>
