import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn
} from "@angular/forms";
import { WidgetCodes, WidgetType } from "clearline-common";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Brand, CouponInfo, CustomizedCampaign, Location } from "../../../../models";
import { CouponService, WidgetService } from "../../../../services";
import { IssueCouponWidgetSettings } from "../issue-coupon-widget-settings/issue-coupon-widget-settings";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { MarketingAction, SmartPageWidgetSettings } from "./smart-page-widget-settings";
import { AuthService } from "@app/core";

@Component({
  selector: "app-smart-page-widget-settings",
  templateUrl: "./smart-page-widget-settings.component.html",
  styleUrls: ["./smart-page-widget-settings.component.scss"],
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SmartPageWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartPageWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SmartPageWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private widgetService: WidgetService,
    private couponService: CouponService,
    public authService: AuthService
  ) {
    super(authService);

    this.initForm();
  }

  @Input() widgetsInThePlan: CustomizedCampaign[];
  @Input() createMode = false;
  @Input() location: Location;
  @Input() brand: Brand;

  @Input() set widgetSettingsJson(value: string) {
    if (value) {
      this.widgetSetting = this.getSmartPageWidgetSettingsOnInit(JSON.parse(value) as SmartPageWidgetSettings);
    } else {
      this.widgetSetting = this.getSmartPageWidgetSettingsOnInit();
    }
  }

  @Output() widgetSettingsReady = new EventEmitter();

  private readonly disabledSortingActionIdsList = [WidgetType.RequestReview, WidgetType.ConnectSocialNetwork];
  private readonly defaultSortedActionIdsList = [
    WidgetType.RequestReview,
    WidgetType.ConnectSocialNetwork,
    WidgetType.EnrollToLoyalty,
    WidgetType.IssueCoupon,
    WidgetType.DownloadMobileApp,
    WidgetType.AnniversaryClub,
    WidgetType.AddPromo,
    WidgetType.SetReminder,
    WidgetType.AddToReferralProgram,
    WidgetType.ScheduleAppointment,
    WidgetType.OneTimePayment,
    WidgetType.SendSurvey,
    WidgetType.AddToWaitlist,
    WidgetType.SeeMenu
  ];

  private _widget: SmartPageWidgetSettings;
  private isCouponListGet = false;
  private unsubscribe$ = new Subject<void>();

  loading = false;
  isDigitalCouponActionEnabled = false;

  widgetType = WidgetType;
  marketingActions: MarketingAction[];
  couponList: CouponInfo[];
  selectedCoupon: CouponInfo;
  lastSelectedCouponId: string;

  // common handlers:

  public set widgetSetting(value: SmartPageWidgetSettings) {
    if (value && !this._widget) {
      this._widget = value;

      setTimeout(() => {
        this.setMarketingActionsToForm(value);
      });
    }

    this.onChange(value);
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  get marketingActionsControl(): UntypedFormArray {
    return this.form?.controls["marketingActions"] as UntypedFormArray;
  }

  private static getAppName(widgetId: number): string {
    const code: string = WidgetCodes[WidgetType[widgetId]];

    return `widgets.${code}.unifiedAppName`;
  }

  private static sortWidgets(widgets: CustomizedCampaign[]): void {
    widgets.sort((a: CustomizedCampaign, b: CustomizedCampaign) => {
      if (a.sortIndex < 0) {
        return b.sortIndex >= 0 ? 1 : 0;
      }

      return a.sortIndex - b.sortIndex;
    });
  }

  private static isCouponAction(actionId: WidgetType): boolean {
    return actionId === WidgetType.IssueCoupon;
  }

  ngOnInit(): void {
    this.widgetSettingsReady.emit();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.markForCheck();
  }

  drop(event: CdkDragDrop<MarketingAction[], any>): void {
    if (event.currentIndex > this.disabledSortingActionIdsList.length - 1) {
      this.dropSuccess(event);
    }
  }

  triggerSwitchAction(actionFormGroup: AbstractControl, index: number): void {
    this.marketingActions[index].enabled = actionFormGroup?.value?.enabled;

    if (SmartPageWidgetSettingsComponent.isCouponAction(actionFormGroup?.value.widgetId)) {
      this.handleCouponActionOnSwitch(actionFormGroup as UntypedFormGroup);
    }
  }

  onCouponListChanged(coupon: CouponInfo): void {
    this.handleOnCouponListChanges(coupon, true, true);
  }

  // form handlers:

  private initForm(): void {
    this.form = new UntypedFormGroup({
      marketingActions: new UntypedFormArray([], this.actionsValidator())
    });
  }

  private actionsValidator(): ValidatorFn {
    return (formArray: UntypedFormArray): { [key: string]: any } | null => {
      const marketingActions: MarketingAction[] = formArray.value;
      const couponActionForm: UntypedFormGroup = formArray.controls?.find((item: UntypedFormGroup) => {
        return SmartPageWidgetSettingsComponent.isCouponAction(item.value.widgetId);
      }) as UntypedFormGroup;

      if (couponActionForm?.value?.enabled) {
        return couponActionForm.value.data ? null : { couponNotSelected: true };
      }

      const isValid = marketingActions?.some((item: MarketingAction) => {
        return item.enabled;
      });

      return isValid ? null : { atLeastOneActionError: true };
    };
  }

  private setMarketingActionsToForm(value: SmartPageWidgetSettings): void {
    value.marketingActions.forEach((item: MarketingAction) => {
      this.marketingActionsControl.push(
        new UntypedFormGroup({
          enabled: new UntypedFormControl(item.enabled),
          widgetId: new UntypedFormControl(item.widgetId),
          sort: new UntypedFormControl(item.sort),
          data: new UntypedFormControl(item.data)
        })
      );

      this.handleEnableItemOnSetToForm(item);
    });

    this.handleActionWithCouponAfterSetForm(value.marketingActions);
  }

  private handleEnableItemOnSetToForm(item: MarketingAction): void {
    if (item.disabledGlobal) {
      const index: number = this.marketingActionsControl?.length - 1;
      const enabledControl: UntypedFormControl = (this.marketingActionsControl?.controls[index] as UntypedFormGroup)?.controls[
        "enabled"
      ] as UntypedFormControl;

      if (enabledControl) {
        enabledControl.disable({ emitEvent: false });
      }
    }
  }

  // main settings:

  private getSmartPageWidgetSettingsOnInit(parsedSettings: SmartPageWidgetSettings = null): SmartPageWidgetSettings {
    if (parsedSettings || this.widgetsInThePlan) {
      this.marketingActions = this.getMarketingActions(parsedSettings);
    } else {
      this.marketingActions = [];
    }

    return {
      marketingActions: this.marketingActions
    };
  }

  private getMarketingActions(parsedSettings: SmartPageWidgetSettings): MarketingAction[] {
    const widgetsUsedOnUnified: CustomizedCampaign[] = this.getWidgetsUsedOnUnified();

    return widgetsUsedOnUnified.map((item: CustomizedCampaign, index: number) => {
      if (parsedSettings && parsedSettings.marketingActions) {
        const appSettings = parsedSettings.marketingActions.find((a) => a.widgetId == item.locationWidgetSettings.defaultWidgetSettingsId);
        if (appSettings)
          return {
            widgetId: appSettings.widgetId,
            enabled: appSettings.enabled,
            sort: appSettings.sort,
            data: appSettings.data,
            name: SmartPageWidgetSettingsComponent.getAppName(appSettings.widgetId),
            icon: this.widgetService.getWidgetIcon(appSettings.widgetId),
            disabledSorting: this.getDisabledSortingAction(appSettings.widgetId),
            disabledGlobal: this.getDisabledGlobalActionOnSettings(appSettings)
          };
      }
      return {
        widgetId: item.id,
        enabled: false,
        sort: index,
        data: "",
        name: SmartPageWidgetSettingsComponent.getAppName(item.id),
        icon: this.widgetService.getWidgetIcon(item.id),
        disabledSorting: this.getDisabledSortingAction(item.id),
        disabledGlobal: this.getDisabledGlobal(item)
      };
    });
  }

  // main setting common getters:

  private getDisabledGlobalActionOnSettings(item: MarketingAction): boolean {
    const actionGlobal: CustomizedCampaign = this.widgetsInThePlan.find(
      (itemInThePlan: CustomizedCampaign) => itemInThePlan.id === item.widgetId
    );

    return this.getDisabledGlobal(actionGlobal);
  }

  private getWidgetsUsedOnUnified(): CustomizedCampaign[] {
    const widgetsUsedOnUnified: CustomizedCampaign[] = this.widgetsInThePlan
      .filter((item: CustomizedCampaign) => item.useForUnified)
      .map((item: CustomizedCampaign) => {
        return {
          ...item,
          sortIndex: this.getSortIndex(item)
        };
      });

    SmartPageWidgetSettingsComponent.sortWidgets(widgetsUsedOnUnified);

    return widgetsUsedOnUnified;
  }

  private getSortIndex(item: CustomizedCampaign): number {
    return this.defaultSortedActionIdsList.findIndex((id: WidgetType) => item.id === id);
  }

  private isConfiguredAction(item: CustomizedCampaign): boolean {
    if (this.createMode) {
      const defaultConfiguredWidgets: WidgetType[] = this.widgetService.defaultConfiguredWidgets;

      return defaultConfiguredWidgets.some((id: WidgetType) => item.id === id);
    }

    return item?.locationWidgetSettings?.configured;
  }

  private getDisabledSortingAction(widgetId: number): boolean {
    return this.disabledSortingActionIdsList.some((itemId: WidgetType) => widgetId === itemId);
  }

  public getDisabledGlobal(item: CustomizedCampaign): boolean {
    const isConfiguredAction: boolean = this.isConfiguredAction(item);

    return !isConfiguredAction || item?.locationWidgetSettings?.disabled;
  }

  // drag and drop handler:

  private dropSuccess(event: CdkDragDrop<MarketingAction[], any>): void {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    this.marketingActions.forEach((item: MarketingAction, index: number) => {
      this.marketingActions[index].sort = index;
    });

    this.marketingActionsControl.clear();
    this.marketingActionsControl.patchValue([], { emitEvent: false });

    this.setMarketingActionsToForm({ marketingActions: this.marketingActions });
    this.changeDetectorRef.markForCheck();
  }

  // coupon handlers:

  private handleCouponActionOnSwitch(actionFormGroup: UntypedFormGroup): void {
    if (actionFormGroup.value.enabled) {
      this.handleCouponActionOnSwitchOn();
    } else {
      this.handleCouponActionOnSwitchOff();
    }
  }

  private handleCouponActionOnSwitchOn(): void {
    this.isDigitalCouponActionEnabled = true;

    if (!this.isCouponListGet) {
      this.setCouponList();
    } else {
      this.handleOnCouponListChangesOnCouponId(this.lastSelectedCouponId, true, true);
    }
  }

  private handleCouponActionOnSwitchOff(): void {
    this.isDigitalCouponActionEnabled = false;

    this.handleOnCouponListChanges(null, true, false);
    this.marketingActionsControl.updateValueAndValidity({ emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  private handleOnCouponListChangesOnCouponId(couponId: string, patchToForm: boolean = false, isActionEnabled: boolean = true) {
    const selectedCoupon: CouponInfo = this.couponList.find((item: CouponInfo) => item.id === couponId);

    this.handleOnCouponListChanges(selectedCoupon, patchToForm, isActionEnabled);
  }

  private handleActionWithCouponAfterSetForm(actions: MarketingAction[]): void {
    const digitalCouponAction: MarketingAction = actions.find((item: MarketingAction) =>
      SmartPageWidgetSettingsComponent.isCouponAction(item.widgetId)
    );

    if (digitalCouponAction?.enabled && !this.isCouponListGet) {
      const couponId: string = digitalCouponAction.data;
      this.isDigitalCouponActionEnabled = !!couponId;

      this.setCouponList(couponId);
    } else {
      this.handleCouponControlValidation(digitalCouponAction, this.selectedCoupon?.id, digitalCouponAction?.enabled);
    }
  }

  private setCouponList(couponId: string = ""): void {
    this.startLoading();

    this.isCouponListGet = true;
    const couponList$: Observable<CouponInfo[]> = this.location?.id
      ? this.couponService.getLocationCoupons(this.location.id)
      : this.couponService.getIndustryCoupons(this.brand.industryId);

    couponList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (couponList: CouponInfo[]) => {
        this.setHandledCouponList(couponList, couponId);

        this.finishLoading();
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.finishLoading();
      }
    );
  }

  private setHandledCouponList(couponList: CouponInfo[], couponId: string): void {
    this.couponList = couponList;

    const couponWidget = this.widgetsInThePlan.find((w) => w.id === WidgetType.IssueCoupon);

    if (couponWidget) {
      const couponSettings: IssueCouponWidgetSettings = JSON.parse(couponWidget.locationWidgetSettings.widgetSettingsJson);

      if (couponSettings) {
        this.couponList = couponList.filter((coupon: CouponInfo) => {
          return couponSettings.activeCoupons.some((couponIdItem: string) => coupon.id === couponIdItem);
        });
      }
    }

    if (couponId) {
      const couponActionForm: UntypedFormGroup = this.getDigitalCouponActionForm();
      const isCouponActionEnabled: boolean = couponActionForm?.value?.enabled;

      if (!this.couponList.some((c) => c.id === couponId)) {
        this.selectedCoupon = null;

        this.patchSelectedCouponToForm(null, isCouponActionEnabled);
      } else {
        this.handleOnCouponListChangesOnCouponId(couponId, false, isCouponActionEnabled);
      }
    }
  }

  private handleOnCouponListChanges(coupon: CouponInfo = null, patchToForm: boolean = false, isActionEnabled: boolean = true): void {
    this.selectedCoupon = coupon;
    const couponAction: MarketingAction = this.marketingActions?.find((item: MarketingAction) =>
      SmartPageWidgetSettingsComponent.isCouponAction(item.widgetId)
    );

    if (this.selectedCoupon) {
      this.lastSelectedCouponId = this.selectedCoupon.id;
    }

    if (couponAction) {
      couponAction.data = coupon?.id;
    }

    this.handleCouponsOnSelection(this.selectedCoupon?.id);

    if (patchToForm) {
      this.patchSelectedCouponToForm(this.selectedCoupon, isActionEnabled);
    }
  }

  private patchSelectedCouponToForm(selectedCoupon: CouponInfo = null, isActionEnabled: boolean = true): void {
    const digitalCouponActionForm: UntypedFormGroup = this.getDigitalCouponActionForm();

    if (digitalCouponActionForm) {
      const selectedCouponId: string = selectedCoupon ? selectedCoupon.id : "";

      digitalCouponActionForm.controls["data"].patchValue(selectedCouponId);

      const digitalCouponAction: MarketingAction = this.marketingActions.find((item: MarketingAction) =>
        SmartPageWidgetSettingsComponent.isCouponAction(item.widgetId)
      );

      this.handleCouponControlValidation(digitalCouponAction, selectedCouponId, isActionEnabled);
    }
  }

  private handleCouponControlValidation(
    digitalCouponAction: MarketingAction,
    selectedCouponId: string,
    isActionEnabled: boolean = true
  ): void {
    const digitalCouponActionForm: UntypedFormGroup = this.getDigitalCouponActionForm();

    if (digitalCouponActionForm) {
      const dataControl = digitalCouponActionForm.controls["data"];
      const hasCouponNotAvailableError = isActionEnabled && digitalCouponAction.data && !selectedCouponId;

      dataControl.setErrors(hasCouponNotAvailableError ? { couponNotAvailable: true } : null);
    }
  }

  private getDigitalCouponActionForm(): UntypedFormGroup {
    return this.marketingActionsControl?.controls?.find((item: UntypedFormGroup) => {
      return SmartPageWidgetSettingsComponent.isCouponAction(item.value.widgetId);
    }) as UntypedFormGroup;
  }

  private handleCouponsOnSelection(selectedCouponId: string): void {
    if (this.couponList?.length) {
      this.couponList.forEach((item: CouponInfo, index: number) => {
        this.couponList[index].isSelected = selectedCouponId && selectedCouponId === item.id;
      });
    }
  }

  // handle loading:

  private startLoading(): void {
    this.loading = true;
  }

  private finishLoading(): void {
    this.loading = false;
  }

  // destroy:

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
