import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TemplateRoutingModule } from "./template-routing.module";
import {
  PrintableTemplateComponent,
  PrintTemplateComponent,
  TemplateComponent,
  TemplatePageComponent,
  TemplatePreviewPageComponent
} from "./template-page";
import { TemplatesCarouselComponent, TemplatesCarouselPreviewComponent } from "./templates-carousel-preview";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CfsTemplatePreviewComponent } from "clearline-common";

@NgModule({
  declarations: [
    PrintTemplateComponent,
    TemplateComponent,
    TemplatePreviewPageComponent,
    TemplatePageComponent,
    PrintableTemplateComponent,
    TemplatesCarouselPreviewComponent,
    TemplatesCarouselComponent
  ],
  imports: [CommonModule, TemplateRoutingModule, CarouselModule, CfsTemplatePreviewComponent]
})
export class TemplateModule {}
