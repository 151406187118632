<div class="printable-template">
  <app-template
    *ngIf="template?.generatedBody"
    [previewType]="TemplatePreviewType.Inactive"
    [template]="template"
    [forPrint]="true"
    (print)="onPrint()">
  </app-template>

  <app-template
    *ngIf="template?.generatedBody"
    [previewType]="TemplatePreviewType.Inactive"
    [template]="template"
    [forPrint]="true"
    (print)="onPrint()">
  </app-template>
</div>
