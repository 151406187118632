<ng-container *transloco="let t; read: 'senders'" class="row">
  <div class="col-xxl-10 col-xl-11 col-lg-12 col-md-12 co-sm-12 col-12">
    <label class="col-form-label font-weight-semibold" *ngIf="!isEdit">
      {{ t("emailoption") }}
    </label>
    <div class="form-check mb-1" *ngIf="!isEdit">
      <input
        class="form-check-input"
        type="radio"
        name="emailRadios"
        [value]="false"
        (ngModelChange)="onChanged($event)"
        [(ngModel)]="hasValue"
        id="emailRadios1"
        checked
      />
      <label class="form-check-label radio-label" for="emailRadios1">
        {{ t("emailShared") }}
        <i [ngbTooltip]="genericSender?.genericEmail" #to="ngbTooltip" container="body" placement="bottom" (mouseout)="to.close()">{{
          "(" + genericSender?.genericEmail + ")"
        }}</i>
      </label>
    </div>
    <div class="input-group mb-3 col-xl-3" *ngIf="!hasValue">
      <ng-select
        [(ngModel)]="businessName"
        (ngModelChange)="onBuisinessNameChange($event)"
        [clearable]="false"
        [items]="businessNames"
        class="input-group-select-first flex-fill"
        style="width: 1%"
      >
      </ng-select>
      <span class="input-group-text" id="basic-addon1">@</span>
      <ng-select
        [clearable]="false"
        (ngModelChange)="onDomainsChanged($event)"
        [(ngModel)]="domain"
        [items]="domains"
        class="input-group-select-last flex-fill"
        style="width: 1%"
      >
      </ng-select>
    </div>
    <div class="form-check mb-3" *ngIf="!isEdit">
      <input
        class="form-check-input"
        type="radio"
        name="emailRadios"
        [value]="true"
        (ngModelChange)="onChanged($event)"
        [(ngModel)]="hasValue"
        id="emailRadios2"
      />
      <label class="form-check-label" for="emailRadios2">
        {{ t("emailCustom") }}
      </label>
    </div>

    <form [formGroup]="form" *ngIf="hasValue">
      <div class="form-group">
        <label class="col-md-3 col-form-label required">{{ t("from") }}</label>
        <input
          class="form-control"
          type="text"
          [placeholder]="t('from')"
          formControlName="from"
          [class.is-invalid]="form.controls['from'].touched && form.controls['from'].errors"
        />
        <ng-container *ngIf="form.controls['from'].touched && form.controls['from'].errors">
          <ng-container *ngIf="form.controls['from'].errors.required">
            <div *transloco="let tc" class="invalid-feedback">
              {{ tc("common.error.required") }}
            </div>
          </ng-container>
          <ng-container *ngIf="form.controls['from'].errors.pattern">
            <div class="invalid-feedback">{{ t("errorFromEmail") }}</div>
          </ng-container>
        </ng-container>
      </div>
      <!-- Uncomment when it will be list of emails and add pattern validation-->
      <!-- <div class="mb-3">
    <label class="col-md-3 col-form-label">Reply to</label>
    <ng-select class="disable-arrow" [items]="[]" formControlName="replyTo"
      [class.is-invalid]="form.controls['replyTo'].touched && form.controls['replyTo'].errors" [addTag]="true"
      [multiple]="true" [selectOnTab]="true" [isOpen]="false" (add)="validateValue($event)" (blur)="onBlur($event)" [placeholder]="Reply to">
    </ng-select>
  </div> -->
      <div class="form-group">
        <label class="col-md-3 col-form-label">{{ t("reply") }}</label>
        <input
          class="form-control"
          type="text"
          [placeholder]="t('reply')"
          formControlName="replyTo"
          [class.is-invalid]="form.controls['replyTo'].touched && form.controls['replyTo'].errors"
        />
        <ng-container *ngIf="form.controls['replyTo'].touched && form.controls['replyTo'].errors">
          <ng-container *ngIf="form.controls['replyTo'].errors.pattern">
            <div class="invalid-feedback">{{ t("errorEmailReply") }}</div>
          </ng-container>
        </ng-container>
      </div>

      <lib-tags-input
        class="form-group"
        [label]="t('cc')"
        [placeholder]="t('cc')"
        [control]="form.controls['cc']"
        [isValidErrorMessage]="t('errorEmailCc')"
        [uniqueErrorMessage]="t('errorCcUniq')"
        (remove)="onFromValidate()">
      </lib-tags-input>

      <lib-tags-input
        class="form-group"
        [label]="t('bcc')"
        [placeholder]="t('bcc')"
        [control]="form.controls['bcc']"
        [isValidErrorMessage]="t('errorEmailBcc')"
        [uniqueErrorMessage]="t('errorBccUniq')"
        (remove)="onFromValidate()">
      </lib-tags-input>

      <hr class="m-0" *ngIf="isEdit && isAdmin" />
      <div class="form-check mt-3 mx-2" *ngIf="isEdit && isAdmin">
        <input formControlName="isApproved" type="checkbox" id="check" class="form-check-input" />
        <label for="check" class="form-check-label">{{ t("approved") }}</label>
      </div>
    </form>
  </div>
</ng-container>
