import { AccountConfigurationDto, User } from "@app/models";

export class Account {
  id?: number;
  companyName: string;
  billingCountry?: number;
  billingCity: string;
  billingWebsite: string;
  billingState: string;
  billingAddress: string;
  billingContactEmail: string;
  billingContactLastName: string;
  billingContactFirstName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingZip: string;
  billingLongState: string;
  isBillingAddress: boolean;
  salesAgentId?: number;
  companyFullName?: string;
  numberOfLocations?: number;
  accountManager?: User;
  isPosIntegrationAvailable?: boolean;
  partnerId?: number;
  partnerName?: string;
  posSystemId?: string;
  companyPosId?: string;
  configuration: AccountConfigurationDto;
  smsApiKey?: string;
}
