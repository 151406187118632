import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SecurityContext } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AppConfigService } from "@app/core";
import {
  ApplicationAccessLevel,
  ApplicationStyles,
  DisplayQrCodeLogoTypes,
  ItemWithLabel,
  QrCodeConfiguration,
  QrCodeFormat,
  QrCodeTemplateName,
  QrCodeWithTemplatePostDto
} from "@app/models";
import { QrCodeGeneratorService } from "@app/services";
import { Observable, of } from "rxjs";
import { auditTime, catchError, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { Unsubscriber } from "../base/unsubscriber.component";

const primaryHtmlTextPlaceholder =
  "Special Offer Restaurant Weekly Deal!<br>Scan the QR Code to save the digital coupon to your phone or email.";

interface LogoUrlOptions {
  ignoreFormValue?: boolean;
  qrLogoType?: DisplayQrCodeLogoTypes;
}

@Component({
  selector: "app-application-styles",
  templateUrl: "./application-styles.component.html",
  styleUrls: ["./application-styles.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationStylesComponent extends Unsubscriber implements OnInit {
  @Input() set styles(value: ApplicationStyles) {
    if (value && JSON.stringify(value) !== JSON.stringify(this._styles)) {
      const { id, name, qrConfiguration, applicationAccessLevel } = value;
      const {
        primaryColor,
        secondaryColor,
        backgroundColor,
        hasFrame,
        qrLogoType,
        primaryTextColor,
        secondaryTextColor,
        buttonTextColor,
        buttonBackgroundColor
      } = qrConfiguration;

      this._styles = value;
      this.form.patchValue({
        id,
        hasFrame,
        hasLogo: qrLogoType !== DisplayQrCodeLogoTypes.None,
        logo: this.getLogoUrl({ qrLogoType }),
        qrLogoType,
        name,
        applicationAccessLevel,
        primary: primaryColor,
        secondary: secondaryColor,
        backgroundColor,
        primaryTextColor,
        secondaryTextColor,
        buttonTextColor,
        buttonBackgroundColor
      });
    }
  }

  @Input() brandLogo = "";
  @Input() hasCallToActionSettings = false;

  @Output() stylesChanged = new EventEmitter<ApplicationStyles>();

  form = new FormGroup({
    id: new FormControl<number>(0),
    primary: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.primaryColor),
    secondary: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.secondaryColor),
    backgroundColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.backgroundColor),
    primaryTextColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.primaryTextColor),
    secondaryTextColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.secondaryTextColor),
    buttonTextColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.buttonTextColor),
    buttonBackgroundColor: new FormControl<string>(this.configSvc.appData.qrCodeConfiguration?.buttonBackgroundColor),
    hasFrame: new FormControl<boolean>(false),
    hasLogo: new FormControl<boolean>(false),
    logo: new FormControl<string>(null),
    name: new FormControl<string>(null),
    applicationAccessLevel: new FormControl<ApplicationAccessLevel>(ApplicationAccessLevel.Private),
    qrLogoType: new FormControl<DisplayQrCodeLogoTypes>(DisplayQrCodeLogoTypes.None)
  });

  readonly link$: Observable<SafeResourceUrl> = this.form.valueChanges.pipe(
    startWith(this.form.getRawValue()),
    auditTime(300),
    distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
    switchMap(() => this.getQrCodeTemplateUrl()),
    tap(() => {
      const changedStyles = this.getChangedStyles();

      this.stylesChanged.emit(changedStyles);
    })
  );

  readonly DisplayQrCodeLogoTypes = DisplayQrCodeLogoTypes;
  readonly logoImageAspectRatio = 5 / 4;
  logoTypeRadioButtons: ItemWithLabel<DisplayQrCodeLogoTypes>[] = [
    { label: "bulkLinks.fields.radioBrand", value: DisplayQrCodeLogoTypes.BrandLogo, disabled: false },
    { label: "bulkLinks.fields.radioCustom", value: DisplayQrCodeLogoTypes.CustomLogo, disabled: false },
    { label: "bulkLinks.fields.radioNone", value: DisplayQrCodeLogoTypes.None, disabled: false }
  ];

  private _styles: ApplicationStyles;

  constructor(
    private configSvc: AppConfigService,
    private sanitizer: DomSanitizer,
    private qrCodeGenerator: QrCodeGeneratorService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.form.controls.qrLogoType.valueChanges.pipe(auditTime(300)).subscribe((qrLogoType: DisplayQrCodeLogoTypes) => {
      const logoUrl = this.getLogoUrl({ ignoreFormValue: true });

      if (qrLogoType === DisplayQrCodeLogoTypes.CustomLogo) {
        this._styles.qrConfiguration.logo = logoUrl;
      }

      this.form.controls.logo.setValue(logoUrl, { emitEvent: false });
      this.form.controls.hasLogo.setValue(qrLogoType !== DisplayQrCodeLogoTypes.None, { emitEvent: false });
      this.cdr.markForCheck();
    });
  }

  private getChangedStyles(): ApplicationStyles {
    const storedLogo = this._styles?.qrConfiguration.logo || null; // can call earlier than `set styles`
    const { qrConfiguration } = this.getStyles();
    const { logo, qrLogoType } = qrConfiguration;
    const changedQrConfiguration = { ...qrConfiguration, logo: qrLogoType === DisplayQrCodeLogoTypes.CustomLogo ? logo : storedLogo };
    const changedStyles = {
      ...this._styles,
      qrConfiguration: { ...changedQrConfiguration }
    };

    return changedStyles;
  }

  private getStyles(): ApplicationStyles {
    const {
      hasFrame,
      logo,
      qrLogoType,
      id,
      name,
      applicationAccessLevel,
      primary,
      secondary,
      backgroundColor,
      primaryTextColor,
      secondaryTextColor,
      buttonTextColor,
      buttonBackgroundColor
    } = this.form.getRawValue();
    const configuration: QrCodeConfiguration = {
      primaryColor: primary,
      secondaryColor: secondary,
      backgroundColor,
      primaryTextColor,
      secondaryTextColor,
      buttonTextColor,
      buttonBackgroundColor,
      qrLogoType,
      logo,
      hasFrame
    };

    return {
      id,
      name,
      applicationAccessLevel,
      qrConfiguration: configuration
    };
  }

  private getQrCodeTemplateUrl(): Observable<SafeResourceUrl> {
    const {
      primary,
      secondary,
      backgroundColor,
      qrLogoType,
      hasFrame,
      hasLogo,
      name,
      primaryTextColor,
      secondaryTextColor,
      buttonTextColor,
      buttonBackgroundColor
    } = this.form.getRawValue();
    const sanitizedPrimaryText = this.sanitizer.sanitize(SecurityContext.HTML, primaryHtmlTextPlaceholder);
    const payload: QrCodeWithTemplatePostDto = {
      logo: hasLogo ? this.getLogoUrl() : null,
      name,
      hasFrame,
      qrLogoType,
      primaryColor: primary,
      secondaryColor: secondary,
      backgroundColor,
      primaryText: sanitizedPrimaryText,
      secondaryText: null,
      primaryTextColor,
      secondaryTextColor,
      buttonTextColor,
      buttonBackgroundColor,
      width: 306, // phone img size
      height: 614, // phone img size
      hasPrintId: false,
      hasCouponCode: false,
      hasSerialNumber: false,
      printId: 0,
      templateName: QrCodeTemplateName.AppStylesPhone,
      serialNumber: null,
      couponCode: null,
      data: "http://clearline.me"
    };

    return this.qrCodeGenerator.generateQrCodeUrlWithHtmlContent(QrCodeFormat.Png, payload).pipe(
      catchError(() => of({ link: "" })),
      map(({ link }) => this.sanitizer.bypassSecurityTrustResourceUrl(link))
    );
  }

  private getLogoUrl(options: LogoUrlOptions = {}): string {
    const { qrLogoType, ignoreFormValue } = options;

    switch (qrLogoType || this.form.controls.qrLogoType.value) {
      case DisplayQrCodeLogoTypes.BrandLogo: {
        return this.brandLogo;
      }
      case DisplayQrCodeLogoTypes.CustomLogo: {
        const storedLogo = this._styles.qrConfiguration.logo;
        const formLogo = this.form.controls.logo.value;

        return ignoreFormValue ? storedLogo : formLogo || storedLogo;
      }
      default: {
        return "";
      }
    }
  }
}
