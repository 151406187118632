import { ApplicationStyles } from "./brand-color-palette";
import { Location } from "./location";
import { DisplayQrCodeLogoTypes } from "@app/models/enums";
import {
  ContentSettings,
  CouponContentSettings,
  DigitalAsset,
  KeyValueAsset,
  MediaContent,
  ScreenSizesJsonAsset,
  TemplateDestinationType,
  TemplateExtendedProperties,
  UrlContentSettings
} from "clearline-common";

export enum DefaultTemplateType {
  None = 0,
  Cfs = 1,
  Print = 2,
  CfsAndPrint = 3
}

export enum GlobalTemplateType {
  System = "System",
  Custom = "Custom",
  Global = "Global"
}

export enum TemplateInfoEnum {
  customContentForCFSId = 17,
  cfsTemplateName = "Editable CFS Template",
  cfsQrCodeTemplateName = "QR Code Editable CFS Template",
  minUrlLength = 10,
  maxUrlLength = 800
}

export class LocationTemplate {
  locationId: number;
  canEnable: boolean;
  enabled: boolean;
}

export class TemplateDto {
  name?: string;
  templateType?: DefaultTemplateType;
  templateProperties?: TemplateProperties;
  enabled: boolean; // need delete
  useOnCfs?: boolean;
  useOnPos?: boolean;
}

export class TemplatePostDto extends TemplateDto {
  defaultTemplateId: number;
  order?: number;
  configuration?: TemplateConfigurationDto;
}

export class TemplatePutDto extends TemplateDto {
  id?: number;
  order: string;
  configuration?: TemplateConfigurationDto;
}

export interface GeneratedTemplateGetDto extends Template {
  templateContentTypeId: number;
}

export class Template {
  id?: number;
  name: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId?: number;
  templateType?: DefaultTemplateType;
  isDefault?: boolean;
  canEnable?: boolean; // need delete
  enabled: boolean; // need delete
  templateProperties?: TemplateProperties;
  generatedBody?: string;
  industryId?: number;
  industryName?: string;
  fullName?: string;
  templateTypeName?: string;
  marketingChannel?: string;
  useOnCfs?: boolean;
  useOnPos?: boolean;
  activeLocationCount?: number;
  locationTemplates?: LocationTemplate[];
  globalTemplateType?: GlobalTemplateType;
  accountIds?: number[];
  useForAllAccounts?: boolean;
  activeAccountsCount?: number;
  activeAccountsCountGlobalTypeView?: string;
  configuration?: TemplateConfigurationDto;
  errors?: TemplateValidationErrorDto[];
  applicationStyles?: ApplicationStyles;
  displayTypeIds?: number[];
  useAllDisplayTypes?: boolean;
}

export interface QrCodeContentSettings {
  backgroundColor?: string;
  backgroundImage?: string;
  logoUrl?: string;
  imageUrl?: string;
  qrCodePrimaryColor?: string;
  qrCodeSecondaryColor?: string;
  qrCodeBackground?: string;
  qrCodeHasFrame?: boolean;
  qrCodeLogo?: string;
  qrCodeLogoType?: DisplayQrCodeLogoTypes;
  qrCodeUrl?: string;
  buttonText?: string;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  qrActionText?: string;
  qrActionColor?: string;
  subtitleText?: string;
  subtitleColor?: string;
  titleText?: string;
  titleColor?: string;
}

export type TemplateProperties = ContentSettings | UrlContentSettings | CouponContentSettings | TemplateExtendedProperties | null;

export class TemplateLocations {
  locationIds: number[];
}
export class GlobalTemplateAccounts {
  useForAllAccounts: boolean;
  accountIds: number[];
}

export interface TemplatesCarouselData {
  templateList?: Template[];
  withPause?: boolean;
  updateOnNewCircle?: boolean;
}

export interface TemplateBasePreviewOptions {
  digitalAsset?: DigitalAsset;
  json?: string;
  screenSizesJsonAsset?: ScreenSizesJsonAsset;
  mediaContent?: MediaContent;
}

export interface TemplatePreviewOptions extends TemplateBasePreviewOptions {
  htmlBody?: string;
  previewTitle?: string;
  previewIcon?: string;
  contentSettings?: ContentSettings;
  defaultParameters?: KeyValueAsset; // from template configuration
}
export interface TemplatePreviewSelectableOptions extends TemplatePreviewOptions {
  id?: number; // id, depending on context - DigitalAsset.Id|Template.Id|...
}

export interface SystemTemplatePutDto {
  id: number;
  useOnCfs: boolean;
  useOnPos: boolean;
  displayTypeIds?: number[];
  useAllDisplayTypes: boolean;
  options: TemplateConfigurationOptionsDto;
}

export interface TemplateConfigurationDto {
  mediaContentId?: number;
  defaultParameters: KeyValueAsset;
  options?: TemplateConfigurationOptionsDto;
  triggers?: TemplateConfigurationTriggerDto[];
}
export interface TemplateConfigurationOptionsDto {
  defaultDestination?: TemplateDestinationDto;
  displayTime?: number;
  image: TemplateImageConfigurationDto;
  logo: TemplateLogoConfigurationDto;
}
export interface TemplateConfigurationTriggerDto {
  triggerType: TemplateConfigurationTriggerType;
  area: TemplateConfigurationTriggerAreaDto;
  action: TemplateConfigurationTriggerActionDto;
}
export interface TemplateConfigurationTriggerAreaDto {
  areaType: TemplateConfigurationTriggerAreaType;
}
export interface TemplateConfigurationTriggerActionDto {
  actionType: TemplateConfigurationTriggerActionType;
  destination: TemplateCustomDestinationDto;
}
export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  separateUrl?: boolean;
  url?: string;
  widgetType?: number;
  externalCouponId?: string;
}
export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface TemplateImageConfigurationDto {
  sourceType: TemplateImageSourceType;
}
export interface TemplateLogoConfigurationDto {
  sourceType: TemplateLogoSourceType;
  brandId?: number;
}

export interface TemplateValidationErrorDto {
  locationId?: number;
  location: Location;
  widgetId?: number;
  externalCouponId?: string;
  messageCode?: string;
}

export interface TemplateValidationResultDto {
  templateId: number;
  isGlobal: boolean;
  errors: TemplateValidationErrorDto[];
}

export interface TemplateAccountLocationsDto {
  accountId: number;
  useForAllLocations: boolean; // All Account Locations. If 'true' - 'LocationIds' property is ignored
  locationIds?: number[];
}

export interface TemplateAssignedAccountsDto {
  useForAllAccounts: boolean; // Is all Accounts assigned. If true - 'Accounts' property is ignored.
  accounts: TemplateAccountLocationsDto[];
}

export interface DisplayTypeDto {
  id: number;
  name?: string;
}

export enum TemplateConfigurationTriggerAreaType {
  Any = "Any"
}
export enum TemplateConfigurationTriggerType {
  None = "None",
  Click = "Click"
}
export enum TemplateConfigurationTriggerActionType {
  IframeNavigation = "IframeNavigation"
}

export enum TemplateImageSourceType {
  None = "None",
  Default = "Default",
  Custom = "Custom"
}

export enum TemplateLogoSourceType {
  None = "None",
  Default = "Default",
  CustomBrand = "CustomBrand",
  Custom = "Custom"
}

export enum TemplatePreviewType {
  Active = "Active",
  Inactive = "Inactive"
}

export enum CFSTemplateType {
  Lottie = "lottie",
  Video = "video",
  Image = "image"
}
