import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Template, TemplateProperties } from "@app/models";
import { Unsubscriber } from "@app/shared/components";
import { Observable } from "rxjs";
import { TemplatePreviewService } from "@app/template/template-preview.service";
import {
  CfsTemplateMediaConfig,
  CFSTemplateType,
  ScreenSizeType,
  TemplatePreviewOptions,
  TemplatePreviewType,
  UrlContentSettings
} from "clearline-common";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  styleUrls: ["./template.component.scss"]
})
export class TemplateComponent extends Unsubscriber implements OnInit, AfterViewInit {
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() forPrint = false;
  @Input() mediaConfig: CfsTemplateMediaConfig = { muted: true, autoplay: true };

  @Input() set template(template: Template) {
    if (template) {
      const { name, generatedBody, configuration, templateProperties } = template;

      this.generatedBody = generatedBody ? this.sanitizer.bypassSecurityTrustHtml(generatedBody as string) : "";
      this.templateType = this.getTemplateType(generatedBody);
      this.title = name;
      this.displayTime = configuration?.options?.displayTime;
      this.templateProperties = templateProperties;

      if (this.templateType === CFSTemplateType.Lottie) {
        this.previewOptions$ = this.templatePreviewService.getLottieOptions(template);
      }
    }
  }

  @Output() print = new EventEmitter();

  readonly TemplateType = CFSTemplateType;
  private readonly smallScreen = 768;
  private isScreenSizeResponsive = false;
  private title = "";
  private displayTime: number;
  private templateProperties: TemplateProperties;

  generatedBody: SafeHtml | undefined;
  screenSize: ScreenSizeType;
  templateType: CFSTemplateType;
  previewOptions$: Observable<TemplatePreviewOptions>;

  @HostListener("window:resize") handleResizeEvent(): void {
    this.setScreenSize();
  }

  constructor(private sanitizer: DomSanitizer, private templatePreviewService: TemplatePreviewService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.templatePreviewService.isScreenSizeResponsive$.subscribe((isScreenSizeResponsive: boolean) => {
      this.isScreenSizeResponsive = isScreenSizeResponsive;
      this.setScreenSize();
    });
  }

  ngAfterViewInit(): void {
    this.initVideoTemplate();
  }

  launchPrint(): void {
    if (this.forPrint) {
      this.print.emit();
    }
  }

  play(): void {
    const videoElement: HTMLVideoElement = this.getVideoPlayer();

    if (videoElement) {
      videoElement.play().then(() => {});
    }
  }

  stop(): void {
    const videoElement: HTMLVideoElement = this.getVideoPlayer();

    if (videoElement) {
      if (!videoElement.paused) {
        videoElement.pause();
      }

      videoElement.currentTime = 0;
    }
  }

  private setScreenSize(): void {
    const updatedScreenSize: ScreenSizeType = this.getScreenSizeType();

    if (this.screenSize !== updatedScreenSize) {
      this.screenSize = updatedScreenSize;
    }
  }

  private initVideoTemplate(): void {
    const videoElement: HTMLVideoElement = this.getVideoPlayer();

    if (videoElement && this.previewType === TemplatePreviewType.Active) {
      const videoDuration: number = +(this.templateProperties as UrlContentSettings)?.ContentDuration;
      const { muted, autoplay } = this.mediaConfig;

      videoElement.muted = muted;
      videoElement.loop = autoplay || !videoDuration || this.displayTime > videoDuration;

      if (autoplay) {
        videoElement.autoplay = true;
      }
    }
  }

  private getScreenSizeType(): ScreenSizeType {
    let updatedScreenSize: ScreenSizeType = ScreenSizeType.Full;

    if (this.isScreenSizeResponsive) {
      if (window.innerWidth <= this.smallScreen) {
        updatedScreenSize = ScreenSizeType.Compact;
      } else if (window.innerHeight >= window.innerWidth) {
        updatedScreenSize = ScreenSizeType.Vertical;
      }
    }

    return updatedScreenSize;
  }

  private getTemplateType(generatedBody: string): CFSTemplateType {
    const temporaryDiv: HTMLElement = document.createElement("div");
    temporaryDiv.innerHTML = generatedBody;
    const templateElement: HTMLElement = temporaryDiv.querySelector(".template");
    const classList: DOMTokenList = (templateElement as HTMLElement)?.classList;

    if (classList?.contains("lottie")) {
      return CFSTemplateType.Lottie;
    } else if (classList?.contains("image")) {
      return CFSTemplateType.Image;
    } else if (classList?.contains("video")) {
      return CFSTemplateType.Video;
    }

    return null;
  }

  private getVideoPlayer(): HTMLVideoElement | null {
    if (this.templateType !== CFSTemplateType.Video) {
      return null;
    }

    return this.elementRef.nativeElement.querySelector("video.template-video");
  }
}
