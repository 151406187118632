export enum BrandSelectionType {
  NoBrand = "NoBrand",
  PrivateBrand = "PrivateBrand",
  PublicBrand = "PublicBrand"
}

export enum PaymentResponsibility {
  Partner = "Partner",
  AccountOwner = "AccountOwner"
}

export enum ActivationCodeStatus {
  Pending = "Pending",
  Sent = "Sent",
  RegistrationStarted = "RegistrationStarted",
  Completed = "Completed"
}

export interface ActivationCodeBase {
  name: string;
  newRegistrationId?: number;
  newRegistrationName?: string;
  newRegistrationUrl?: string;
  partnerId?: number;
  brandType: BrandSelectionType;
  accountId?: number;
  pricingPlanId?: number;
  paymentResponsibility: PaymentResponsibility;
  deviceCount?: number;
  active?: boolean;
}

export interface ActivationCodeGetDto extends ActivationCodeBase {
  id: number;
  accountName: string;
  brandId: number;
  brandName: string;
  code: string;
  createdByName: string;
  createdOn: string;
  modifiedByName: string;
  modifiedOn: string;
  partnerName: string;
  pricingPlanName: string;
  status: ActivationCodeStatus;
}

export interface ActivationCodePostDto extends ActivationCodeBase {
  count: number;
}

export interface ActivationCodePutDto {
  brandType: BrandSelectionType;
  newRegistrationId?: number;
  pricingPlanId?: number;
  paymentResponsibility: PaymentResponsibility;
}

export interface SendActivationCodeRequestDto {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export interface AccountInfoGetDto {
  id: string;
  externalIdentifier: string;
  companyName: string;
  billingCity: string;
  billingState: string;
  billingAddress: string;
  billingContactEmail: string;
  billingContactLastName: string;
  billingContactFirstName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingZip: string;
  salesAgentId?: number;
  isPosIntegrationAvailable: boolean;
  active: boolean;
  companyPosId: string;
  posSystemId: string;
  partnerId: number;
}

export interface AssignActivationCodeDto {
  accountId: number;
  brandId: number;
  brandType: BrandSelectionType;
}

export interface ActivationCodeShortGetDto {
  id: string;
  code: string;
  name: string;
  paymentResponsibility: PaymentResponsibility;
}
