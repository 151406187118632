<form [formGroup]="form" *transloco="let t; read: 'applicationStyles'">
  <div class="row">
    <!-- left column -->
    <div class="col">
      <div class="row">
        <div class="col">
          <app-colors-form-control
            formControlName="primary"
            [placeholder]="t('color.primary')"
            [tooltip]="t('tooltip.primary')">
          </app-colors-form-control>

          <app-colors-form-control
            formControlName="backgroundColor"
            [placeholder]="t('color.backgroundColor')"
            [tooltip]="t('tooltip.backgroundColor')"
            [colorPickerType]="'predefined'">
          </app-colors-form-control>
        </div>

        <div class="col">
          <app-colors-form-control
            formControlName="secondary"
            [placeholder]="t('color.secondary')"
            [tooltip]="t('tooltip.secondary')">
          </app-colors-form-control>
        </div>
      </div>

      <div class="col">
        <app-checkbox size="lg" [control]="form.controls.hasFrame" [inlineLabel]="t('hasFrame')" [isSlider]="true"></app-checkbox>
      </div>

      <div class="col">
        <div class="mt-2">
          <label class="card-title">{{ 'bulkLinks.switchLogo' | transloco }}</label>
        </div>

        <app-radio-group
            formControlName="qrLogoType"
            groupName="qrLogoType"
            [items]="logoTypeRadioButtons"
        >
          <app-image-upload-form-control
            formControlName="logo"
            storageFolder="logo"
            [uploadButtonVisible]="form.controls.qrLogoType.value === DisplayQrCodeLogoTypes.CustomLogo"
            [hasCropper]="true"
            [aspectRatio]="logoImageAspectRatio"
          ></app-image-upload-form-control>
        </app-radio-group>
      </div>

      <div class="row">
        <div class="{{ hasCallToActionSettings ? 'col' : 'col-md-6'}}">
          <app-colors-form-control
            formControlName="primaryTextColor"
            [placeholder]="t('color.primaryTextColor')"
            [tooltip]="t('tooltip.primaryTextColor')"
          ></app-colors-form-control>

          <app-colors-form-control
            formControlName="secondaryTextColor"
            [placeholder]="t('color.secondaryTextColor')"
            [tooltip]="t('tooltip.secondaryTextColor')"
          ></app-colors-form-control>
        </div>

        <div class="col" *ngIf="hasCallToActionSettings">
          <app-colors-form-control
            formControlName="buttonTextColor"
            [placeholder]="t('color.buttonTextColor')"
            [tooltip]="t('tooltip.buttonTextColor')"
          ></app-colors-form-control>

          <app-colors-form-control
            formControlName="buttonBackgroundColor"
            [placeholder]="t('color.buttonBackgroundColor')"
            [tooltip]="t('tooltip.buttonBackgroundColor')"
          ></app-colors-form-control>
        </div>
      </div>
    </div>

    <!-- right column -->
    <div class="col">
      <div class="d-flex justify-content-center mt-3 mb-3">
        <img class="styles-preview" *ngIf="link$ | async as link" [src]="link" alt="Application Styles Image" />
      </div>
    </div>
  </div>
</form>
