<div class="carousel-wrapper">
  <div class="carousel" *ngIf="ready$ | async; else loader">
    <div class="carousel-inner">
      <div *ngFor="let template of templates$ | async; index as i" class="item" [class.active]="i === nextSlideIndex">
        <app-template
          [previewType]="TemplatePreviewType.Active"
          [mediaConfig]="{ muted: true, autoplay: false }"
          [template]="template"
        ></app-template>
      </div>
    </div>
  </div>

  <ng-template #loader>
    <div class="spinner-block">
      <div class="spinner-screen">
        <div class="loading-spinner">
          <div class="loading fa fa-spinner fa-spin fa-5x"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
