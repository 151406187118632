import { Injectable } from "@angular/core";
import { Template, TemplatePreviewSelectableOptions } from "@app/models";
import { DigitalAssetService, MediaContentService, TemplateService } from "@app/services";
import { NULL_OBSERVABLE } from "clearline-api";
import { combineLatest, forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import {
  ContentSettings,
  DigitalAsset,
  MediaContent,
  ScreenSizesJsonAsset,
  ScreenSizeType,
  TemplatePreviewOptions
} from "clearline-common";

@Injectable({ providedIn: "root" })
export class TemplatePreviewService {
  isScreenSizeResponsive$ = new Subject<boolean>();

  constructor(
    private templateService: TemplateService,
    private mediaContentService: MediaContentService,
    private digitalAssetService: DigitalAssetService
  ) {}

  getLottieOptions(template: Template): Observable<TemplatePreviewOptions> {
    const { configuration } = template;

    const configurationOptions$ = (_template: Template) => {
      const _configuration = _template.configuration;
      const _templateProperties = _template.templateProperties;
      const { mediaContentId, defaultParameters } = _configuration;

      return mediaContentId
        ? this.mediaContentService.getMediaContent(mediaContentId).pipe(
            tap((mediaContent: MediaContent) => {
              const isScreenSizeResponsive = mediaContent.digitalAssets.some(
                (item: DigitalAsset) => item.configuration?.screenSize && item.configuration.screenSize !== ScreenSizeType.Full
              );

              this.isScreenSizeResponsive$.next(isScreenSizeResponsive);
            }),
            mergeMap((mediaContent: MediaContent) => this.getOptionByMediaContent(mediaContent)),
            map((options: TemplatePreviewOptions) => {
              return { ...options, contentSettings: _templateProperties, defaultParameters };
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };
    const restOptions$ = (_template: Template) => {
      const contentSettings: ContentSettings = _template.templateProperties as ContentSettings;
      const assetId = contentSettings?.assetId;

      return assetId
        ? this.digitalAssetService.getAsset(assetId).pipe(
            tap(() => {
              this.isScreenSizeResponsive$.next(false);
            }),
            mergeMap((asset: DigitalAsset) => {
              const contentUrl = (asset as DigitalAsset)?.contentUrl;

              return combineLatest([of(asset), this.getContentByUrl$(contentUrl)]);
            }),
            map(([digitalAsset, json]: [DigitalAsset, string]) => {
              return digitalAsset ? { contentSettings, digitalAsset, json } : null;
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };

    return configuration?.mediaContentId ? configurationOptions$(template) : restOptions$(template);
  }

  getTemplatePreviewSelectableOptions(mediaContent: MediaContent[]): Observable<TemplatePreviewSelectableOptions[]> {
    return combineLatest(mediaContent.map((item: MediaContent) => this.getOptionByMediaContent(item))).pipe(
      map((options: TemplatePreviewSelectableOptions[]) => {
        return options.map((item: TemplatePreviewSelectableOptions) => {
          return { ...item, id: item.mediaContent.id };
        });
      })
    );
  }

  private getOptionByMediaContent(mediaContent: MediaContent): Observable<TemplatePreviewOptions> {
    const digitalAssets: DigitalAsset[] = mediaContent?.digitalAssets || [];
    const contentUrlAsset: ScreenSizesJsonAsset = digitalAssets.reduce((acc: ScreenSizesJsonAsset, item: DigitalAsset) => {
      if (item.configuration) {
        const screenSize: ScreenSizeType = item.configuration.screenSize;

        acc[screenSize] = item.contentUrl;
      }

      return acc;
    }, {} as ScreenSizesJsonAsset);

    return this.getScreenSizesJsonAsset$(contentUrlAsset).pipe(
      map((screenSizesJsonAsset: ScreenSizesJsonAsset) => {
        return { mediaContent, screenSizesJsonAsset };
      }),
      catchError(() => NULL_OBSERVABLE)
    );
  }

  private getScreenSizesJsonAsset$(contentUrlAsset: ScreenSizesJsonAsset): Observable<ScreenSizesJsonAsset> {
    const jsonList$: Observable<ScreenSizesJsonAsset | null>[] = Object.keys(contentUrlAsset).map((key: string) => {
      const contentUrl: string = contentUrlAsset[key as ScreenSizeType] as string;

      return this.getContentByUrl$(contentUrl).pipe(map((json: string) => (json ? { [key as ScreenSizeType]: json } : null)));
    });

    return forkJoin(jsonList$).pipe(
      map((assetList) => {
        return assetList.reduce((acc, item: ScreenSizesJsonAsset | null) => {
          if (item) {
            acc = { ...acc, ...item };
          }
          return acc;
        }, {} as ScreenSizesJsonAsset) as ScreenSizesJsonAsset;
      })
    );
  }

  private getContentByUrl$(contentUrl: string = ""): Observable<string> {
    if (contentUrl) {
      return this.templateService.getContentByUrl(contentUrl as string).pipe(catchError(() => of("")));
    }

    return of("");
  }
}
